<template>
  <div v-if="debugData?.app.deliveryCreation && debugData?.apiToken">
    <DebugFrame>
      <div class="text-sm text-center rounded-sm leading-2xs">
        <div
          v-if="!data.deliveries?.length && !success"
          class="bg-status-success-base p-2xs m-2xs"
          @click.prevent.stop="createDelivery()"
        >
          Create Delivery For Order
        </div>
        <div
          v-else-if="loading"
          class="text-xs bg-status-warn-base p-2xs m-2xs"
        >
          Rödel rödel rödel...
        </div>
        <div v-else class="bg-grey-base p-2xs m-2xs">
          Delivery already existing
        </div>
      </div>
    </DebugFrame>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '~/server/transformers/shop/order/types';
import useGetDebugData from '../useGetDebugData';
import DebugFrame from './debugFrame.vue';
import { useSecurePost } from '~/composables/dataFetching/genericFetchers';

const props = defineProps({
  data: {
    type: Object as PropType<Order>,
    required: true,
  },
});

const debugData = useGetDebugData();
const success = ref(null);
const loading = ref(false);

async function createDelivery() {
  try {
    loading.value = true;
    const updatedOrder = await updateOrderLineNumbers();
    const result = await useSecurePost(
      `/api/${useSiteIdent()}/admin/${debugData.value.apiToken}/debug/shop/history/createDelivery`,
      {
        order: updatedOrder,
        client_id: debugData.value.shopware.client_id,
        client_secret: debugData.value.shopware.client_secret,
      },
    );
    success.value = true;
    // eslint-disable-next-line no-console
    console.debug('Delivery created for order:', result);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not create delivery for Order', e);
  } finally {
    loading.value = false;
  }
}

async function updateOrderLineNumbers() {
  try {
    const result = await useSecurePost(
      `/api/${useSiteIdent()}/admin/${debugData.value.apiToken}/debug/shop/history/updateOrderLineNumbers`,
      {
        order: props.data,
        client_id: debugData.value.shopware.client_id,
        client_secret: debugData.value.shopware.client_secret,
      },
    );
    // eslint-disable-next-line no-console
    console.debug('Order line numbers updated:', result);
    return result;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not update order line numbers', e);
    throw e;
  }
}
</script>
